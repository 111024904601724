import React, { useEffect, useState, useRef } from "react";
import DashboardElementBase from "./DashboardElementBase";
import { ethers } from "ethers";
function DashboardBase() {
  const [data, setData] = useState([]);
  const dataRefetch = useRef([]);
  const [error, setError] = useState(null);
  const [loading, setLoad] = useState(true);
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState("latest");
  const [searchTerm, setSearchTerm] = useState("");
  const [search, setSearch] = useState("");
  const [shakeIndices, setShakeIndices] = useState([]);
  const [isConnected, setIsConnected] = useState(false);
  const [walletAddress, setWalletAddress] = useState("");
  const itemsPerPage = 30; // Adjust this value as needed

  const checkIfWalletIsConnected = async () => {
    // console.log("checking if wallet is connected"); // Removed
    if (typeof window.ethereum !== "undefined") {
      try {
        const accounts = await window.ethereum.request({
          method: "eth_accounts",
        });
        const web3Provider = new ethers.BrowserProvider(window.ethereum);
        const signerInstance = web3Provider.getSigner();
        if (accounts.length > 0) {
          setIsConnected(true);
          setWalletAddress(accounts[0]);
        } else {
          setIsConnected(false);
          setWalletAddress("");
        }
      } catch (error) {
        console.error("Error checking accounts:", error);
      }
    } else {
      // console.log("Ethereum object not found"); // Removed
    }
  };

  const fetchData = async () => {
    try {
      setError(null);
      setLoad(true);
      let response;
      if (filters === "mysales" && isConnected) {
        response = await fetch(
          `https://etherfun.pro/apibase/${filters}?address=${walletAddress}&page=${page}`
        );
      } else {
        response = await fetch(
          `https://etherfun.pro/apibase/${filters}?page=${page}`
        );
      }

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const fetchedData = await response.json();
      setData(fetchedData);
      dataRefetch.current = fetchedData;
      // console.log("Fetched default data:", fetchedData); // Removed
    } catch (error) {
      setError(error);
      // console.log(error); // Removed
    } finally {
      setLoad(false);
    }
  };

  const reFetchData = async () => {
    // console.log("refetching"); // Removed
    try {
      if (error !== null || search !== "") {
        // console.log("error or search"); // Removed
        return;
      }
      const response = await fetch(
        `https://etherfun.pro/apibase/${filters}?page=${page}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const fetchedData = await response.json();

      if (fetchedData[0].tokenaddress !== dataRefetch.current[0].tokenaddress) {
        // console.log("updating new data"); // Removed
        setLoad(true);
        dataRefetch.current = fetchedData;
        setData(fetchedData);
      } else {
        // console.log("data is the same"); // Removed
      }
      // console.log("Fetched default data:", fetchedData); // Removed
    } catch (error) {
      // setError(error); // Uncommented if needed
      // console.log(error); // Removed
    } finally {
      setLoad(false);
    }
  };

  const searchData = async () => {
    try {
      setError(null);
      setLoad(true);
      const response = await fetch(
        `https://etherfun.pro/apibase/token?ca=${search}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const fetchedData = await response.json();

      if (fetchedData.length === 0) {
        setSearch("");
        setSearchTerm(""); // Clear the search input
        alert("No results found. Displaying default data.");
      } else {
        setData([fetchedData]);
        // console.log("Fetched search data:", fetchedData); // Removed
      }
    } catch (error) {
      setError(error);
      // console.log(error); // Removed
    } finally {
      setLoad(false);
    }
  };

  // Fetch default data when page or filters change, but only if no search is active
  useEffect(() => {
    checkIfWalletIsConnected();

    if (search === "") {
      fetchData();
    }
    const interval = setInterval(reFetchData, 10000);

    // Clear the interval when the component unmounts
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, filters]);

  // Debounce the search input
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearch(searchTerm.trim());
      setPage(1);
    }, 500); // Adjust the delay as needed (in milliseconds)

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  // Fetch search data when search term changes
  useEffect(() => {
    if (search !== "") {
      searchData();
    } else {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleFilterChange = (newFilter) => {
    setFilters(newFilter);
    setPage(1);
  };
  useEffect(() => {
    const interval = setInterval(() => {
      const randomIndices = [];
      const maxIndices = Math.min(data.length, 5); // Limit to available items or 5, whichever is smaller

      if (data.length > 0) {
        const availableIndices = [...Array(data.length).keys()]; // Create an array of all possible indices
        while (randomIndices.length < maxIndices) {
          const randomIndex = Math.floor(
            Math.random() * availableIndices.length
          );
          randomIndices.push(availableIndices.splice(randomIndex, 1)[0]); // Select and remove the index
        }
      }

      setShakeIndices(randomIndices); // Set the indices, even if it's less than 5
      setTimeout(() => setShakeIndices([]), 300); // Clear shake effect after 300ms
    }, 8000); // Shake every 8 seconds

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [data.length]);

  return (
    <div>
      {/* Filters Section */}
      <div className="my-2 flex flex-row justify-center mb-12">
        <div className="flex flex-row mb-1 sm:mb-0">
          <div className="block relative">
            <span className="h-full absolute inset-y-0 left-0 flex items-center pl-2">
              <svg
                viewBox="0 0 24 24"
                className="h-4 w-4 fill-current text-gray-400"
              >
                <path d="M10 4a6 6 0 100 12 6 6 0 000-12zm-8 6a8 8 0 1114.32 4.906l5.387 5.387a1 1 0 01-1.414 1.414l-5.387-5.387A8 8 0 012 10z"></path>
              </svg>
            </span>
            <input
              placeholder="Search Token Address"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="appearance-none rounded-r rounded-l sm:rounded-r-none border border-gray-600 block pl-8 pr-6 py-2 w-full bg-gray-800 text-sm placeholder-gray-400 text-white focus:bg-gray-700 focus:placeholder-gray-300 focus:text-white focus:outline-none"
            />
          </div>
          <div className="relative">
            <select
              value={filters}
              className="appearance-none h-full rounded-r border block w-full bg-gray-800 border-gray-600 text-white py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-gray-700 focus:border-gray-500"
              onChange={(e) => handleFilterChange(e.target.value)}
            >
              <option value="latest">sort: recent</option>
              <option value="topfilled">sort: top pumps</option>
              <option value="marketcap">sort: market cap</option>
              <option value="launched">sort: deployed</option>
              {walletAddress && (
                <option value="mysales">sort: my bought tokens</option>
              )}
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-300">
              <svg
                className="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
          </div>
        </div>
      </div>

      {/* Data Section */}
      <div>
        {loading ? (
          <div className="w-full h-auto  grid grid-cols-1 xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl: 2xl:grid-cols-5 px-2 sm:px-8 gap-8 xl:gap-8">
            {Array.from({ length: 50 }).map((_, index) => (
              <div key={index}>
                <div className="py-44 rounded-[14px] skeleton mx-4 xl:mx-8 w-30"></div>
              </div>
            ))}
          </div>
        ) : error ? (
          <div className="flex justify-center items-center h-screen">
            <div className="text-red-500 text-lg">Error: {error.message}</div>
          </div>
        ) : data.length === 0 && search !== "" ? (
          // Display a message when no search results are found
          <div className="flex justify-center items-center h-screen">
            <div className="text-gray-500 text-lg">No results found.</div>
          </div>
        ) : (
          <>
            {/* Data Display */}
            <div className="w-full h-auto  grid grid-cols-1 xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl: 2xl:grid-cols-5 px-2 sm:px-8 gap-8 xl:gap-8">
              {data.map((item, index) => (
                <div
                  key={index}
                  className={` ${shakeIndices.includes(index) ? "shake" : ""}`}
                >
                  <DashboardElementBase data={item} />
                </div>
              ))}
            </div>

            {/* Pagination */}
            {data.length > 0 && (
              <nav className="flex justify-center w-max mx-auto mt-12">
                <ul className="inline-flex -space-x-px text-base h-10">
                  <li>
                    <button
                      onClick={() => handlePageChange(page - 1)}
                      disabled={page === 1}
                      className={`px-4 py-2 rounded-full ${
                        page === 1
                          ? "cursor-not-allowed text-gray-400"
                          : "hover:bg-gray-100"
                      }`}
                    >
                      Previous
                    </button>
                  </li>
                  <div className="w-10 h-10 flex items-center justify-center rounded-full">{page}</div>
                  {/*[1, 2].map((pageNumber) => (
                    <li key={pageNumber}>
                      <button
                      disabled
                        onClick={() => handlePageChange(pageNumber)}
                        className={`w-10 h-10 flex items-center justify-center rounded-full ${
                          page === pageNumber
                            ? "bg-[#355c82] text-white"
                            : "hover:bg-gray-100"
                        }`}
                      >
                        {pageNumber}
                      </button>
                    </li>
                  ))}*/}
                  <li>
                    <button
                      onClick={() => handlePageChange(page + 1)}
                      className="px-4 py-2 rounded-full"
                    >
                      Next
                    </button>
                  </li>
                </ul>
              </nav>
            )}
          </>
        )}
        <div className="h-24"></div>
      </div>
    </div>
  );
}

export default DashboardBase;
