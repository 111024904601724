import { Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom"; // Import useNavigate and useLocation hooks

import React, { useEffect, useState } from "react";
import TokensFeed from "../TokensFeed";

function Navbar() {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [selectedChain, setSelectedChain] = useState("eth");
  const location = useLocation(); // Get the current location

  useEffect(() => {
    if (location.pathname.startsWith("/base")) {
      setSelectedChain("base");
    } else {
      setSelectedChain("eth");
    }
  }, [location.pathname]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiEndpoint =
          selectedChain === "base"
            ? "https://etherfun.pro/apibase/latest?page=1"
            : "https://etherfun.pro/api2/latest?page=1"; // Adjust API endpoint based on chain

        const response = await fetch(apiEndpoint);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setData(data);
      } catch (error) {
        setError(error);
      }
    };

    fetchData();
  }, [selectedChain]); // Fetch data whenever the selectedChain changes

  const getLinkPath = (path) => {
    return selectedChain === "base" ? `/base${path}` : path;
  };

  return (
    <div className="flex gap-2 pb-[0px] border-b-[1px] border-white/10 w-full overflow-x-hidden">
      <div className="pl-4 pt-3 w-full">
        <ul className="flex space-x-4 text-white w-[240px]">
          <li>
            <Link to={getLinkPath("/")} className="hover:underline">
              <img
                className="w-7 h-7 flex items-center"
                src="/ethervista.png"
                alt="Etherfun"
              />
            </Link>
          </li>
          <li>
            <Link
              to={getLinkPath("/launch")}
              className="hover:underline text-transparent bg-clip-text bg-gradient-to-r from-yellow-500 to-blue-500 font-bold"
            >
              [Launch]
            </Link>
          </li>
          <li>
            <a
              href="https://docs.ethervista.app/etherfun"
              target="_blank"
              className="hover:underline"
              rel="noreferrer"
            >
              [How it works]
            </a>
          </li>
        </ul>
      </div>
      <div className="w-full">
        <TokensFeed data={data} className="w-full" />
      </div>
    </div>
  );
}

export default Navbar;
