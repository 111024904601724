import React from "react";
function timeAgo(unixTimestamp) {
  // Convert to milliseconds if the timestamp is in seconds
  if (unixTimestamp < 10000000000) {
    // Likely in seconds if it's below this value
    unixTimestamp *= 1000;
  }

  const now = Date.now();
  const secondsElapsed = Math.floor((now - unixTimestamp) / 1000);

  if (secondsElapsed < 60) {
    return `${secondsElapsed}s`;
  }

  const minutesElapsed = Math.floor(secondsElapsed / 60);
  if (minutesElapsed < 60) {
    return `${minutesElapsed}min`;
  }

  const hoursElapsed = Math.floor(minutesElapsed / 60);
  if (hoursElapsed < 24) {
    return `${hoursElapsed}h`;
  }

  const daysElapsed = Math.floor(hoursElapsed / 24);
  if (daysElapsed < 30) {
    return `${daysElapsed}d`;
  }

  const monthsElapsed = Math.floor(daysElapsed / 30);
  if (monthsElapsed < 12) {
    return `${monthsElapsed}mon`;
  }

  const yearsElapsed = Math.floor(monthsElapsed / 12);
  return `${yearsElapsed}yrs `;
}

function FeedElement({ data, rank }) {
  return (
    <a
      href={`/token/${data.tokenaddress}`}
      target="_blank"
      className={`flex w-56 justify-center h-8 items-center  gap-2  hover:bg-white/5  p-4 px-20 py-3 my-2  rounded-sm `}
    >
      <h3 className="text-gray-400 font-mono text-sm">#{rank + 1}</h3>
      <img
        className="w-6 h-6"
        src={data.logourl}
        onError={(e) => {
          e.target.onerror = null;
          e.target.src =
            "https://dd.dexscreener.com/ds-data/tokens/ethereum/0xc9bca88b04581699fab5aa276ccaff7df957cbbf.png?size=md";
        }}
        alt="Token Logo"
      />
      <h3 className={` text-[11px] font-medium  text-white `}>{data.symbol}</h3>
      <h3
        className={` text-[12px] font-bold  ${
          data.change < 0 ? "text-red-500" : "text-green-500"
        }`}
      >
        {Math.round(data.change)}%
      </h3>
      <h3 className=" text-[13px] font-mono font-medium text-gray-400">
        {timeAgo(data.createdon)}
      </h3>
    </a>
  );
}

export default FeedElement;
