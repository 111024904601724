import React from "react";
import { Link } from "react-router-dom";
import { timeAgo } from "../functions/ui_functions";
function formatNumber(num) {
  if (num >= 1000) {
    // If number is greater than or equal to 1000, convert to 'k' format
    return (num / 1000).toFixed(1) + "k";
  }
  return num.toString(); // If less than 1000, return the number as is
}
function DashboardElement({ data }) {
  return (
    <Link
      to={`/token/${data.tokenaddress}`}
      className=" h-auto w-full max-w-[250px] h-[365px] flex flex-col gap-4 pt-4 relative bg-blue-500/5 rounded-xl px-4 py-4 border-[5px] border-blue-500/40 justify-between"
    >
      <div className="flex justify-center ">
        <span className="text-center font-bold">${data.symbol}</span>{" "}
      </div>
      <img
        className="w-[100px] h-auto mx-auto"
        onError={(e) => {
          e.target.onerror = null;
          e.target.src =
            "https://dd.dexscreener.com/ds-data/tokens/ethereum/0xc9bca88b04581699fab5aa276ccaff7df957cbbf.png?size=md";
        }}
        alt="Token Logo"
        src={data.logourl}
      />
      <div>
        <div className="flex gap-6 justify-around mb-2.5">
          <p className="font-normal text-[10px] text-gray-400">
            {timeAgo(data.createdon)}
          </p>
          <p className="hidden sm:block font-normal text-[10px] text-blue-300">
            created by {data.creator.slice(0, 10)}
          </p>
        </div>
        <h3 class=" text-[12px] sm:text-md font-bold text-start">
          {data.name.slice(0, 30)}
        </h3>
        <div className="w-72 h-auto ">
          <p class="  text-start max-w-[200px]">
            <span class="text-[10px] sm:text-sm font-semibold overflow-hidden text-left text-gray-400 break-words max-w-[200px]">
              {data.description.slice(0, 64)}
            </span>
            <span class="text-center">{data.contractaddress}</span>
          </p>
        </div>

        <div className="w-full text-xs text-start font-semibold pt-1 text-green-500">
          Market cap : {formatNumber(Math.round(data.marketcap))}
        </div>

        <p
          className={`w-12   hidden absolute right-4 top-12 text-xs ${
            data.symbol === "VISTA" ? "!block" : "hidden"
          }`}
        ></p>
      </div>
    </Link>
  );
}

export default DashboardElement;
