import React from "react";

import { useEffect, useRef, useState } from "react";
import { ethers } from "ethers";
import {
  createSale,
  getCreatorTokens,
  getSaleMetadata,
  getSale,
} from "../../functions/factoryFunctions";
import { getContractData } from "../../functions/saleFunctions";
import { popupManager } from "../common/PopupManager";
import skelly from "../../assets/skelly.gif";
import sparkle from "../../assets/sparkle.gif";
function Launch() {
  const [provider, setProvider] = useState(null);
  const [signer, setSigner] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [walletAddress, setWalletAddress] = useState("");
  const [userSales, setUserSales] = useState([]);
  const [name, setName] = useState("");
  const [ticker, setTicker] = useState("");
  const [imgURL, setimgURL] = useState("");
  const [description, setDescription] = useState("");
  const [website, setWebsite] = useState("");
  const [twitter, setTwitter] = useState("");
  const [telegram, setTelegram] = useState("");
  const [initialBuy, setinitialBuy] = useState("");
  const [Sales, setSales] = useState(null);
  const [FetchedSales, setFetchedSales] = useState(null);

  const [selectedTab, setSelectedTab] = useState("launch"); // Added state for selected tab
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevents form from refreshing the page

    try {
      const file = event.target.files[0];
      if (!file) return; // Check if a file was selected

      const formData = new FormData();
      formData.append("image", file); // Append the file to the form data

      const response = await fetch("https://etherfun.pro/meta/upload", {
        method: "POST",
        body: formData, // Send the form data containing the binary image
      });

      if (!response.ok) {
        throw new Error("Failed to upload image");
      }

      const result = await response.json();
      setimgURL(result.imageUrl);
      // console.log("Image uploaded successfully:", result); // Removed console.log
    } catch (error) {
      popupManager.addPopup({
        id: Date.now(),
        type: "error",
        message: "Something went wrong uploading image " + error.toString(),
        duration: 5000,
      });
      // console.error("Error uploading image:", error); // Removed console.log
    }
  };
  const FetchMySales = async (wallet) => {
    try {
      const creatorTokens = await getCreatorTokens(wallet);
      // console.log(creatorTokens); // Removed console.log
      setSales(creatorTokens);

      // Fetch data with a delay between each call
      const fetchedSales = [];
      const fetchedSalesInfo = [];

      for (let i = 0; i < creatorTokens.length; i++) {
        const token = creatorTokens[i];

        // Fetch each item with a delay
        const saleInfo = await FetchSaleInfo(token);
        const sale = await getSale(token);

        fetchedSales.push(saleInfo);
        fetchedSalesInfo.push(sale);

        // Add delay between each call (e.g., 500 ms)
        await delay(500);
      }

      // Combine fetched data
      const combinedData = fetchedSales.map((sale, index) => ({
        ...sale,
        ...fetchedSalesInfo[index],
        token: creatorTokens[index],
      }));

      setFetchedSales(combinedData);
      // console.log("Combined creator tokens: ", combinedData); // Removed console.log
    } catch (error) {
      // console.error("Error fetching sales:", error); // Removed console.log
      popupManager.addPopup({
        id: Date.now(),
        type: "error",
        message: "Error fetching sales: " + error.toString(),
        duration: 6000,
      });
    }
  };

  const FetchSaleInfo = async (tokenAddress) => {
    const creatorTokens = await getSaleMetadata(tokenAddress);
    return creatorTokens;
  };
  const LaunchSale = async () => {
    let id = Date.now();
    let chainIDHex = await window.ethereum.request({ method: "eth_chainId" });
    const chainId = await parseInt(chainIDHex, 16);
    console.log(chainId);
    if (chainId !== 1) {
      setIsConnected(false);
      setWalletAddress("");
      popupManager.addPopup({
        id: Date.now(),
        type: "error",
        message: "Please Connect to ETH Network! ",
        duration: 6000,
      });
      return;
    }
    try {
      // console.log(imgURL); // Removed console.log
      let provider = await new ethers.BrowserProvider(window.ethereum);

      let tx_signer = await provider.getSigner();
      // console.log(tx_signer); // Removed console.log
      popupManager.addPopup({
        id: id,
        type: "message",
        message: "Creating new Sale: " + name,
      });
      if (initialBuy === "") {
        setinitialBuy(0);
      }
      if (initialBuy > 0.2) {
        throw Error("Cannot Buy more than 0.2 ETH");
      }
      let buy = await createSale(
        name,
        ticker,
        imgURL,
        website,
        twitter,
        telegram,
        description,
        initialBuy,
        signer
      );
      // console.log(buy); // Removed console.log
      popupManager.removePopup(id);
      let success_id = Date.now();
      popupManager.addPopup({
        id: success_id,
        type: "success",
        message: "Created new Sale: " + name,
      });
      setTimeout(() => {
        FetchMySales(walletAddress);
      }, 6000);

      setTimeout(() => {
        popupManager.removePopup(success_id);
      }, 6000);
      // console.log(buy); // Removed console.log
    } catch (e) {
      popupManager.removePopup(id);

      popupManager.addPopup({
        id: Date.now(),
        type: "error",
        message: "Error trying to launch sale: " + e.toString(),
        duration: 6000,
      });
      // console.log("Error trying to launch sale", e); // Removed console.log
    }
  };
  const checkConnection = async () => {
    if (typeof window.ethereum !== "undefined") {
      try {
        // Check if any accounts are connected
        const accounts = await window.ethereum.request({
          method: "eth_accounts",
        });
        let chainIDHex = await window.ethereum.request({
          method: "eth_chainId",
        });
        const chainId = await parseInt(chainIDHex, 16);
        console.log(chainId);
        if (chainId !== 1) {
          setIsConnected(false);
          setWalletAddress("");
          popupManager.addPopup({
            id: Date.now(),
            type: "error",
            message: "Please Connect to ETH Network! ",
            duration: 6000,
          });
          return;
        }
        const web3Provider = new ethers.BrowserProvider(window.ethereum);
        setProvider(web3Provider);
        const signerInstance = web3Provider.getSigner();
        setSigner(signerInstance);
        if (accounts.length > 0) {
          setIsConnected(true);
          setWalletAddress(accounts[0]);
        } else {
          setIsConnected(false);
          setWalletAddress("");
        }
      } catch (error) {
        popupManager.addPopup({
          id: Date.now(),
          type: "error",
          message: "Error checking accounts:" + error.toString(),
          duration: 6000,
        });
        // console.error("Error checking accounts:", error); // Removed console.log
      }
    }
  };
  const initializeProvider = async () => {
    try {
      let provider;
      let signer;
      if (window.ethereum == null) {
        popupManager.addPopup({
          id: Date.now(),
          type: "error",
          message: "Try unlocking wallet, or installing Metamask/Phantom: ",
          duration: 6000,
        });
        provider = ethers.getDefaultProvider();
      } else {
        provider = new ethers.BrowserProvider(window.ethereum);
        signer = await provider.getSigner();
      }
      await setProvider(provider);
      await setSigner(signer);
    } catch (e) {
      popupManager.addPopup({
        id: Date.now(),
        type: "error",
        message:
          "Try unlocking wallet, or installing Metamask/Phantom: " +
          e.toString(),
        duration: 6000,
      });
    }
  };
  useEffect(() => {
    if (isConnected && walletAddress) {
      FetchMySales(walletAddress);
    } else {
      setUserSales([]);
    }
  }, [isConnected, walletAddress]);

  useEffect(() => {
    checkConnection();

    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setIsConnected(true);
          setWalletAddress(accounts[0]);
        } else {
          setIsConnected(false);
          setWalletAddress("");
        }
      });
    }

    return () => {
      if (window.ethereum) {
        window.ethereum.removeListener("accountsChanged", () => {});
      }
    };
  }, []);

  return (
    <div className="background-animation ">
      <img className="skelly" src={skelly} alt="Skelly Gif" />
      {FetchedSales && (
        <div className="tab-container flex justify-center mt-4">
          <button
            className={`px-4 py-2 ${
              selectedTab === "launch"
                ? "border-b-[3px] border-green-500 font-bold"
                : "text-gray-500"
            }`}
            onClick={() => setSelectedTab("launch")}
          >
            Launch a Sale
          </button>
          <button
            className={`px-4 py-2 ${
              selectedTab === "sales"
                ? "border-b-[3px] border-green-500 font-bold"
                : "text-gray-500"
            }`}
            onClick={() => setSelectedTab("sales")}
          >
            Your Launches
          </button>
        </div>
      )}
      {selectedTab === "launch" && (
        <>
          <div className="flex justify-center mt-2">
            <img
              className="w-20 h-20 flex items-center"
              src="/ethervistagif.gif"
              alt="Etherfun Gif"
            />
          </div>
          <div className="text-center flex justify-center ">
            <h1 className="text-3xl lg:text-4xl font-bold relative w-48 font-mono">
              Launchpad
              <p className="text-xs text-gray-400 mx-auto">
                Powered by Ethervista and DegenAds
              </p>
            </h1>
          </div>

          <div className="max-w-sm mx-auto text-start mt-4 px-4 lg:px-auto">
            <div className="mb-3">
              <div className="mb-3">
                <label
                  htmlFor="name"
                  className="block mb-2 text-sm font-medium  text-white"
                >
                  name:
                </label>
                <input
                  type="name"
                  id="name"
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                  className="launchinput"
                  required
                  maxLength={30}
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="ticker"
                  className="block mb-2 text-sm font-medium  text-white"
                >
                  ticker:
                </label>
                <input
                  type="ticker"
                  id="ticker"
                  value={ticker}
                  onChange={(event) => setTicker(event.target.value)}
                  className="launchinput"
                  required
                />
              </div>
              <div className="mb-3 py-2">
                <label
                  htmlFor="imageURL"
                  className="block mb-2 text-sm font-medium  text-white"
                >
                  image URL (max 2mb):
                </label>
                <input
                  type="file"
                  id="imageFile"
                  onChange={handleSubmit} // Trigger upload when a file is selected
                  className="launchinput "
                  accept="image/*" // Limit to image files only
                  required
                />
                {imgURL && <div className="text-blue-400"> {imgURL}</div>}
              </div>
              <div className="mb-3">
                <label
                  htmlFor="description"
                  className="block mb-2 text-sm font-medium  text-white"
                >
                  description:
                </label>
                <textarea
                  id="description"
                  rows="2"
                  value={description}
                  onChange={(event) => setDescription(event.target.value)}
                  className="launchinputdesc"
                  required
                  maxLength={250}
                ></textarea>
              </div>
              <div className="mb-3">
                <label
                  htmlFor="website"
                  className="block mb-2 text-sm font-medium  text-white"
                >
                  website:
                </label>
                <input
                  type="website"
                  id="website"
                  value={website}
                  onChange={(event) => setWebsite(event.target.value)}
                  className="launchinput"
                  placeholder="(optional)"
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="twitter"
                  className="block mb-2 text-sm font-medium  text-white"
                >
                  twitter:
                </label>
                <input
                  type="twitter"
                  id="twitter"
                  value={twitter}
                  onChange={(event) => setTwitter(event.target.value)}
                  className="launchinput"
                  placeholder="(optional)"
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="telegram"
                  className="block mb-2 text-sm font-medium  text-white"
                >
                  telegram:
                </label>
                <input
                  type="telegram"
                  id="telegram"
                  value={telegram}
                  onChange={(event) => setTelegram(event.target.value)}
                  className="launchinput"
                  placeholder="(optional)"
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="initialBuy"
                  className="block mb-2 text-sm font-medium  text-white"
                >
                  Your initial buy amount (ETH) (Max 0.2):
                </label>
                <input
                  type="initialBuy"
                  id="initialBuy"
                  value={initialBuy}
                  onChange={(event) => setinitialBuy(event.target.value)}
                  className="launchinput"
                  placeholder="(optional)"
                />
              </div>
            </div>
            <div className="mb-1">
              {isConnected ? (
                <button
                  className="text-white bg-green-600 hover:bg-green-400 focus:ring-4 focus:outline-none
                   focus:ring-blue-300 rounded-lg text-sm w-full px-5 py-2.5 text-center font-bold font-mono z-100"
                  onClick={LaunchSale}
                >
                  Submit
                </button>
              ) : (
                <button
                  className="text-white bg-green-600 hover:bg-green-400 focus:ring-4 focus:outline-none
                   focus:ring-blue-300 rounded-lg text-sm w-full px-5 py-2.5 text-center font-bold font-mono z-100"
                  onClick={initializeProvider}
                >
                  Connect Wallet
                </button>
              )}
            </div>

            <p className="text-sm font-bold text-start text-green-500 pb-8">
              Fee ~0.0005 ETH
            </p>
          </div>
        </>
      )}
      {selectedTab === "sales" && (
        <>
          <div className="flex-row items-center justify-center w-full gap-12 space-y-7 mt-12 h-screen">
            {FetchedSales && FetchedSales.length > 0 ? (
              FetchedSales.map((item, index) => (
                <div
                  key={index}
                  className="p-4 w-[900px] mx-auto h-16 px-12 bg-white/10 flex justify-center "
                >
                  <div className="flex w-[900px] justify-between gap-2 items-center">
                    <div className="w-20">
                      <p className="text-md font-semibold">{item.name}</p>
                    </div>
                    <div>
                      <img
                        className="w-8 h-8 mr-4 mt-1 "
                        src={item.logoUrl}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src =
                            "https://dd.dexscreener.com/ds-data/tokens/ethereum/0xc9bca88b04581699fab5aa276ccaff7df957cbbf.png?size=md";
                        }}
                        alt="not valid"
                      />
                    </div>
                    <div>
                      <a
                        className="text-blue-400 text-sm"
                        href={`https://etherfun.app/token/` + item.token}
                        target="_blank"
                      >
                        Sale Page
                      </a>
                    </div>
                    {/* Sale Details */}
                    <div className="flex flex-col w-1/2 items-center justify-center mb-3">
                      <div className="text-xs text-gray-400">
                        Total Raised: {item.totalRaised} ETH
                      </div>

                      {/* Progress Bar */}
                      <div className="relative w-full h-3 bg-gray-400 rounded-lg overflow-hidden mt-2">
                        <div
                          className="bg-green-500 h-full rounded-lg"
                          style={{
                            width: `${Math.min(
                              (item.totalRaised / item.saleGoal) * 100,
                              100
                            )}%`,
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="flex items-center justify-center flex-col sm:flex-row">
                <img className="sparkle-launch" src={sparkle} alt="Sparkle" />

                <div className="empty">Wow, such empty!</div>
                <img className="sparkle-launch" src={sparkle} alt="Sparkle" />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default Launch;
