import React, { useEffect, useState } from "react";
import DashboardBase from "../DashboardBase";
import Filters from "../Filters";
import "../../Home.css";
import rainGif from "../../assets/manidhaya.gif";
import sparkle from "../../assets/sparkle.gif";
import etherfun from "../../assets/etherfun.gif";
import { initializeApp } from "firebase/app";
import { Link } from "react-router-dom";

import {
  getFirestore,
  collection,
  onSnapshot,
  addDoc,
  serverTimestamp,
  query,
  orderBy,
  limit,
} from "firebase/firestore";
import { getAuth, signInAnonymously, onAuthStateChanged } from "firebase/auth";

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCqjUaPjYJodwdSp-KxPNtrG8txd0TnHZ0",
  authDomain: "etherfunchat.firebaseapp.com",
  projectId: "etherfunchat",
  storageBucket: "etherfunchat.appspot.com",
  messagingSenderId: "978274845586",
  appId: "1:978274845586:web:8855a30ecfd5571400774b",
  measurementId: "G-B4WTFZXL89",
};
const colors = [
  "#952A30",
  "#DE8CB2",
  "#684329",
  "#31AA74",
  "#723EC6",
  "#0B4701",
  "#EFCB75",
  "#C6BF28",
  "#7FA6A5",
  "#7FA6A5",
];
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

function Base() {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [user, setUser] = useState(null);

  useEffect(() => {
    const rainContainer = document.querySelector(".rain-container");
    const numberOfGifs = 30;

    for (let i = 0; i < numberOfGifs; i++) {
      const gif = document.createElement("img");
      gif.src = rainGif;
      gif.className = "rain-gif";
      gif.style.left = Math.random() * 100 + "vw";
      gif.style.animationDuration = Math.random() * 3 + 2 + "s";
      gif.style.animationDelay = Math.random() * 5 + "s";
      rainContainer.appendChild(gif);
    }

    return () => {
      while (rainContainer.firstChild) {
        rainContainer.removeChild(rainContainer.firstChild);
      }
    };
  }, []);

  // Fetch last 30 messages from Firestore, ordered by timestamp
  useEffect(() => {
    const messagesQuery = query(
      collection(db, "messages"),
      orderBy("timestamp", "desc"),
      limit(30)
    );

    const unsubscribe = onSnapshot(
      messagesQuery,
      (snapshot) => {
        const messagesData = snapshot.docs.map((doc) => doc.data());
        setMessages(messagesData.reverse()); // Reverse to show oldest at top
      },
      (error) => {
        // console.error("Error fetching messages:", error); // Removed console.log
      }
    );

    return () => unsubscribe();
  }, []);

  // Authentication state listener
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);

  const sendMessage = async (e) => {
    e.preventDefault();
    if (!user) {
      alert("You must be logged in to send a message.");
      return;
    }
    try {
      await addDoc(collection(db, "messages"), {
        text: input,
        timestamp: serverTimestamp(),
        uid: user.uid,
      });
      setInput("");
    } catch (error) {
      // console.error("Error sending message:", error); // Removed console.log
      alert("Failed to send message. Please try again.");
    }
  };

  const handleAnonymousLogin = async () => {
    try {
      await signInAnonymously(auth);
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <div className="background-animation z--1000">
      <div className="rain-container z--1000"></div>
      <div className="flex flex-row justify-center items-center gap-10 z--1000">
        <img src={sparkle} alt="Sparkle" className="sparkle-gif z--1000" />
        <div className="flex flex-col items-center gap-2 justify-center pt-12 z--1000">
          <div className="flex flex-row gap-2 items-center justify-center pt-12 z--1000">
            <img src={etherfun} alt="Etherfun" className="etherfun-gif" />
          </div>
          <h2 className="text-center font-bold text-sm sm:text-lg pt-2">
            The #1 Protocol for launching Tokens on Base
          </h2>

          <h2
            className="text-center font-bold text-sm sm:text-md text-white/40 font-mono mb-2"
            style={{ transform: "translateY(-5px)" }}
          >
            Leveraging the #1 DEX Fee Protocol
          </h2>
          <Link
            to="/launch"
            className=" hover:underline text-transparent bg-clip-text bg-gradient-to-r from-yellow-500   to-blue-500 font-bold mx-auto mb-2"
          >
            [Launch]
          </Link>
        </div>
        <img src={sparkle} alt="Sparkle" className="sparkle-gif" />
      </div>

      {/* Chat display is always visible */}
      <div className="chat-container">
        <div className="messages scrollable">
          {messages.length > 0 ? (
            messages.map((msg, index) => {
              const randomColor =
                colors[Math.floor(Math.random() * colors.length)]; // Generate random color
              const truncatedText =
                msg.text.length > 100
                  ? msg.text.slice(0, 100) + "..."
                  : msg.text; // Limit to 100 characters
              return (
                <div
                  key={index}
                  style={{
                    color: randomColor,
                    whiteSpace: "pre-wrap",
                    maxWidth: "300px",
                  }}
                >
                  {truncatedText}
                </div>
              );
            })
          ) : (
            <p>No messages yet...</p>
          )}
        </div>
        <div className="auth-container">
          {!user && (
            <button className="anonymous" onClick={handleAnonymousLogin}>
              Login Anonymously
            </button>
          )}
        </div>
        {user ? (
          <form className="messageform" onSubmit={sendMessage}>
            <input
              value={input}
              onChange={(e) => setInput(e.target.value)}
              placeholder="Type a message..."
              style={{
                color: "white",
                outline: "none",
                width: "250px",
                backgroundColor: "transparent",
              }} // Added transparent background
              className="inputmsg mt-2"
            />
            <button className="sendbutton" type="submit">
              Send
            </button>
          </form>
        ) : (
          <p className="login-prompt"></p>
        )}
        {/* Anonymous Authentication Button */}
      </div>
      <DashboardBase />
    </div>
  );
}

export default Base;
