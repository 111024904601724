import React from 'react';

function Filters() {

  return (
<div class="my-2 flex sm:flex-row flex-col justify-center mb-12">
  <div class="flex flex-row mb-1 sm:mb-0">
    <div class="block relative">
        <span class="h-full absolute inset-y-0 left-0 flex items-center pl-2">
        <svg viewBox="0 0 24 24" class="h-4 w-4 fill-current text-gray-400">
            <path d="M10 4a6 6 0 100 12 6 6 0 000-12zm-8 6a8 8 0 1114.32 4.906l5.387 5.387a1 1 0 01-1.414 1.414l-5.387-5.387A8 8 0 012 10z"></path>
        </svg>
        </span>
        <input placeholder="Search Token"
        class="appearance-none rounded-r rounded-l sm:rounded-r-none border border-gray-600 block pl-8 pr-6 py-2 w-full bg-gray-800 text-sm placeholder-gray-400 text-white focus:bg-gray-700 focus:placeholder-gray-300 focus:text-white focus:outline-none" />
    </div>
    <div class="relative">
      <select
        class="appearance-none h-full rounded-r border block w-full bg-gray-800 border-gray-600 text-white py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-gray-700 focus:border-gray-500">
        <option>sort: recent</option>
        <option>sort: relevant</option>
        <option>sort: market cap</option>
        <option>sort: my launches</option>
        <option>sort: my deployed tokens</option>

      </select>
      <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-300">
        <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
        </svg>
      </div>
    </div>
             
  </div>

</div>
  );
}

export default Filters;