function formatNumber(num) {
  try {
    if (num >= 1000) {
      return (num / 1000).toFixed(1) + "k";
    }
    return num.toString();
  } catch (e) {
    // console.error("something went wrong with formatting date")
  }
}

function timeAgo(unixTimestamp) {
  // Convert to milliseconds if the timestamp is in seconds
  if (unixTimestamp < 10000000000) {
    // Likely in seconds if it's below this value
    unixTimestamp *= 1000;
  }

  const now = Date.now();
  const secondsElapsed = Math.floor((now - unixTimestamp) / 1000);

  if (secondsElapsed < 60) {
    return `${secondsElapsed} seconds ago`;
  }

  const minutesElapsed = Math.floor(secondsElapsed / 60);
  if (minutesElapsed < 60) {
    return `${minutesElapsed} minutes ago`;
  }

  const hoursElapsed = Math.floor(minutesElapsed / 60);
  if (hoursElapsed < 24) {
    return `${hoursElapsed} hours ago`;
  }

  const daysElapsed = Math.floor(hoursElapsed / 24);
  if (daysElapsed < 30) {
    return `${daysElapsed} days ago`;
  }

  const monthsElapsed = Math.floor(daysElapsed / 30);
  if (monthsElapsed < 12) {
    return `${monthsElapsed} months ago`;
  }

  const yearsElapsed = Math.floor(monthsElapsed / 12);
  return `${yearsElapsed} years ago `;
}
export { timeAgo, formatNumber };
