import React, { useEffect, useState } from "react";
import FeedElement from "./FeedElement";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay"; // Import the autoplay styles
import { Autoplay } from "swiper/modules";
import "../Home.css"; // Importing home.css from the root folder

function TokensFeed({ data }) {
  const [wiggleIndices, setWiggleIndices] = useState([]);

  useEffect(() => {
    const interval = setInterval(() => {
      const randomIndices = [];
      const maxSlides = Math.min(data.length, 5); // Ensure we don't exceed available slides

      // Only generate random indices if there are items in data
      if (data.length > 0) {
        const availableIndices = [...Array(data.length).keys()]; // Get an array of indices
        while (randomIndices.length < maxSlides) {
          const randomIndex = Math.floor(
            Math.random() * availableIndices.length
          );
          randomIndices.push(availableIndices.splice(randomIndex, 1)[0]); // Select and remove from available
        }
      }

      setWiggleIndices(randomIndices); // Set the random wiggle indices
      setTimeout(() => setWiggleIndices([]), 300); // Reset after 300ms
    }, 8000); // Repeat every 8 seconds

    return () => clearInterval(interval); // Cleanup on component unmount
  }, [data.length]);

  return data.length > 0 ? (
    <Swiper
      spaceBetween={3}
      className="h-full w-full overflow-hidden"
      initial-slide="0"
      modules={[Autoplay]}
      autoplay={{
        delay: 3000, // Autoplay delay (3000ms = 3 seconds)
        disableOnInteraction: false, // Don't stop autoplay after user interaction
      }}
      breakpoints={{
        140: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        340: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        480: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 4,
          spaceBetween: 40,
        },
        800: {
          slidesPerView: 5,
          spaceBetween: 50,
        },
        1000: {
          slidesPerView: 6,
          spaceBetween: 50,
        },
        1150: {
          slidesPerView: 7,
          spaceBetween: 50,
        },
        1324: {
          slidesPerView: 8,
          spaceBetween: 50,
        },
        1524: {
          slidesPerView: 9,
          spaceBetween: 50,
        },
        1724: {
          slidesPerView: 8,
          spaceBetween: 5,
        },
      }}
      onSwiper={(swiper) => {
        /* Removed console.log(swiper) */
      }}
    >
      {data.map((item, index) => (
        <SwiperSlide
          key={index}
          className={wiggleIndices.includes(index) ? "wiggle" : ""}
        >
          <FeedElement data={item} rank={index} />
        </SwiperSlide>
        
      ))}
    </Swiper>
  ) : (
    <Swiper
      spaceBetween={3}
      className="h-full w-full overflow-x-hidden"
      initial-slide="0"
      modules={[Autoplay]}
      autoplay={{
        delay: 3000, // Autoplay delay (3000ms = 3 seconds)
        disableOnInteraction: false, // Don't stop autoplay after user interaction
      }}
      breakpoints={{
        140: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        340: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        480: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 4,
          spaceBetween: 40,
        },
        800: {
          slidesPerView: 5,
          spaceBetween: 50,
        },
        1000: {
          slidesPerView: 6,
          spaceBetween: 50,
        },
        1150: {
          slidesPerView: 7,
          spaceBetween: 50,
        },
        1324: {
          slidesPerView: 8,
          spaceBetween: 50,
        },
        1524: {
          slidesPerView: 9,
          spaceBetween: 50,
        },
        1724: {
          slidesPerView: 8,
          spaceBetween: 5,
        },
      }}
      onSwiper={(swiper) => {
        /* Removed console.log(swiper) */
      }}
    >
      {Array.from({ length: 20 }).map((_, index) => (
        <SwiperSlide key={index}>
          <div className="skeleton flex w-40 xl:w-48 justify-center mt-2 h-7 items-center ml-4" />
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

export default TokensFeed;
