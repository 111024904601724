import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom"; // Import useNavigate and useLocation hooks
import eth_logo from "../../assets/eth.svg";
import base_logo from "../../assets/base.png";

function ChainSelector() {
  const [selectedChain, setSelectedChain] = useState("eth");
  const navigate = useNavigate();
  const location = useLocation(); // Get the current location

  const chains = [
    { value: "eth", label: "ETHEREUM", logo: eth_logo },
    { value: "base", label: "BASE", logo: base_logo },
  ];

  const handleSelect = (e) => {
    const chain = e.target.value;
    if (chain !== selectedChain) {
      setSelectedChain(chain);

      const currentPath = location.pathname;
      const hasToken = currentPath.includes("token");

      // Redirect logic based on "token" in the path
      if (chain === "base") {
        if (hasToken) {
          navigate("/base"); // Redirect to Base home
        } else {
          navigate(`/base${currentPath.replace("/base", "")}`);
        }
      } else if (chain === "eth") {
        if (hasToken) {
          navigate("/"); // Redirect to Ethereum home
        } else {
          navigate(currentPath.replace("/base", ""));
        }
      }
    }
    console.log(`Selected chain: ${chain}`);
  };

  // useEffect to check URL on component mount
  useEffect(() => {
    if (location.pathname.startsWith("/base")) {
      setSelectedChain("base");
    } else {
      setSelectedChain("eth");
    }
  }, [location.pathname]);

  return (
    <div className="z-50 absolute top-[71px] right-48 lg:right-60">
      <select
        value={selectedChain}
        onChange={handleSelect}
        className="select flex items-center px-[4px] py-[0px] text-[13px] rounded-full bg-gray-500/50 hover:bg-gray-400/50 border-[#36404c] hover:border-[#4e5a65] border-[4px] focus:outline-none"
      >
        {chains.map((chain) => (
          <option
            key={chain.value}
            value={chain.value}
            className="flex items-center bg-[#36404c] focus:[#4e5a65] hover:bg-[#4e5a65] justify-center text-center "
          >
            {chain.label}
          </option>
        ))}
      </select>
    </div>
  );
}

export default ChainSelector;
